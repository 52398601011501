import { DateTime } from 'luxon';
import { BadgeType, Coordinate, Room, StaffMember } from './';

export default class Badge {
  static thaw(badgeObj) {
    if (badgeObj == null) return null;
    const badge = new Badge();
    if (badgeObj.serialNumber != null) badge.setSerialNumber(badgeObj.serialNumber);
    if (badgeObj.nodeAddress != null) badge.setNodeAddress(badgeObj.nodeAddress);
    if (badgeObj.lastSeenTimestamp != null) badge.setLastSeenTimestamp(DateTime.fromISO(badgeObj.lastSeenTimestamp));
    if (badgeObj.batteryLevel != null) badge.setBatteryLevel(badgeObj.batteryLevel);
    if (badgeObj.lastHubRssi != null) badge.setLastHubRssi(badgeObj.lastHubRssi);
    if (badgeObj.firmwareVersion != null) badge.setFirmwareVersion(badgeObj.firmwareVersion);
    if (badgeObj.isActive != null) badge.setIsActive(badgeObj.isActive == null ? null : (badgeObj.isActive ? true : false));
    if (badgeObj.lastHubUsedInfrared != null) badge.setLastHubUsedInfrared(badgeObj.lastHubUsedInfrared == null ? null : (badgeObj.lastHubUsedInfrared ? true : false));
    if (badgeObj.assignedToId != null) badge.setAssignedToID(badgeObj.assignedToId);
    if (badgeObj.displayMessage != null) badge.setDisplayMessage(badgeObj.displayMessage);
    if (badgeObj.badgeTypeId != null) badge.setBadgeTypeID(badgeObj.badgeTypeId);
    if (badgeObj.lastPositionTimestamp != null) badge.setLastPositionTimestamp(DateTime.fromISO(badgeObj.lastPositionTimestamp));
    if (badgeObj.lastSeenCoordinateId != null) badge.setLastSeenCoordinateID(badgeObj.lastSeenCoordinateId);
    if (badgeObj.lastSeenRoomId != null) badge.setLastSeenRoomID(badgeObj.lastSeenRoomId);
    if (badgeObj.lastSeenCoordinate != null) badge.setLastSeenCoordinate(Coordinate.thaw(badgeObj.lastSeenCoordinate));
    if (badgeObj.lastSeenRoom != null) badge.setLastSeenRoom(Room.thaw(badgeObj.lastSeenRoom));
    if (badgeObj.badgeType != null) badge.setBadgeType(BadgeType.thaw(badgeObj.badgeType));
    if (badgeObj.belongsTo != null) badge.setBelongsTo(StaffMember.thaw(badgeObj.belongsTo));
    return badge;
  }

  static thawList(badgeObjList) {
    if (badgeObjList == null) return [];
    var badges = [];
    for (var i = 0; i < badgeObjList.length; i++) {
      badges.push(Badge.thaw(badgeObjList[i]));
    }
    return badges;
  }

  static freeze(badge) {
    if (badge == null) return null;
    var badgeObj = {};
    if (badge.getSerialNumber() != null) badgeObj.serialNumber = badge.getSerialNumber();
    if (badge.getNodeAddress() != null) badgeObj.nodeAddress = badge.getNodeAddress();
    if (badge.getLastSeenTimestamp() != null) badgeObj.lastSeenTimestamp = DateTime.toISO(badge.getLastSeenTimestamp());
    if (badge.getBatteryLevel() != null) badgeObj.batteryLevel = badge.getBatteryLevel();
    if (badge.getLastHubRssi() != null) badgeObj.lastHubRssi = badge.getLastHubRssi();
    if (badge.getFirmwareVersion() != null) badgeObj.firmwareVersion = badge.getFirmwareVersion();
    if (badge.getIsActive() != null) badgeObj.isActive = badge.getIsActive() == null ? null : (badge.getIsActive() ? 1 : 0);
    if (badge.getLastHubUsedInfrared() != null) badgeObj.lastHubUsedInfrared = badge.getLastHubUsedInfrared() == null ? null : (badge.getLastHubUsedInfrared() ? 1 : 0);
    if (badge.getAssignedToID() != null) badgeObj.assignedToId = badge.getAssignedToID();
    if (badge.getDisplayMessage() != null) badgeObj.displayMessage = badge.getDisplayMessage();
    if (badge.getBadgeTypeID() != null) badgeObj.badgeTypeId = badge.getBadgeTypeID();
    if (badge.getLastPositionTimestamp() != null) badgeObj.lastPositionTimestamp = DateTime.toISO(badge.getLastPositionTimestamp());
    if (badge.getLastSeenCoordinateID() != null) badgeObj.lastSeenCoordinateId = badge.getLastSeenCoordinateID();
    if (badge.getLastSeenRoomID() != null) badgeObj.lastSeenRoomId = badge.getLastSeenRoomID();
    if (badge.getLastSeenCoordinate() != null) badgeObj.lastSeenCoordinate = Coordinate.freeze(badge.getLastSeenCoordinate());
    if (badge.getLastSeenRoom() != null) badgeObj.lastSeenRoom = Room.freeze(badge.getLastSeenRoom());
    if (badge.getBadgeStatus() != null) badgeObj.badgeType = BadgeType.freeze(badge.getBadgeType());
    if (badge.getBelongsTo() != null) badgeObj.belongsTo = StaffMember.freeze(badge.getBelongsTo());
    return badgeObj;
  }

  static freezeList(badges) {
    if (badges == null) return [];
    var badgeObjList = [];
    for (var i = 0; i < badges.length; i++) {
      badgeObjList.push(Badge.freeze(badges[i]));
    }
    return badgeObjList;
  }

  constructor() {
    this.setSerialNumber(null);
    this.setNodeAddress(null);
    this.setLastSeenTimestamp(null);
    this.setBatteryLevel(null);
    this.setLastHubRssi(null);
    this.setFirmwareVersion(null);
    this.setIsActive(null);
    this.setLastHubUsedInfrared(null);
    this.setAssignedToID(null);
    this.setDisplayMessage(null);
    this.setBadgeTypeID(null);
    this.setLastPositionTimestamp(null);
    this.setLastSeenCoordinateID(null);
    this.setLastSeenRoomID(null);
    this.setLastSeenCoordinate(null);
    this.setLastSeenRoom(null);
    this.setBadgeType(null);
    this.setBelongsTo(null);
  }

  /* -------------------------
     -------------------------
     -------- SETTERS --------
     -------------------------
     ------------------------- */

  setSerialNumber(serialNumber) {
    this.serialNumber = serialNumber;
  }

  setNodeAddress(nodeAddress) {
    this.nodeAddress = nodeAddress;
  }

  setLastSeenTimestamp(lastSeenTimestamp) {
    this.lastSeenTimestamp = lastSeenTimestamp;
  }

  setBatteryLevel(batteryLevel) {
    this.batteryLevel = batteryLevel;
  }

  setLastHubRssi(lastHubRssi) {
    this.lastHubRssi = lastHubRssi;
  }

  setFirmwareVersion(firmwareVersion) {
    this.firmwareVersion = firmwareVersion;
  }

  setIsActive(isActive) {
    this.isActive = isActive;
  }

  setLastHubUsedInfrared(lastHubUsedInfrared) {
    this.lastHubUsedInfrared = lastHubUsedInfrared;
  }

  setAssignedToID(assignedToID) {
    this.assignedToID = assignedToID;
  }

  setDisplayMessage(displayMessage) {
    this.displayMessage = displayMessage;
  }

  setBadgeTypeID(badgeTypeID) {
    this.badgeTypeID = badgeTypeID;
  }

  setLastPositionTimestamp(lastPositionTimestamp) {
    this.lastPositionTimestamp = lastPositionTimestamp;
  }

  setLastSeenCoordinateID(lastSeenCoordinateID) {
    this.lastSeenCoordinateID = lastSeenCoordinateID;
  }

  setLastSeenRoomID(lastSeenRoomID) {
    this.lastSeenRoomID = lastSeenRoomID;
  }

  setLastSeenCoordinate(lastSeenCoordinate) {
    this.lastSeenCoordinate = lastSeenCoordinate;
  }

  setLastSeenRoom(lastSeenRoom) {
    this.lastSeenRoom = lastSeenRoom;
  }

  setBadgeType(badgeType) {
    this.badgeType = badgeType;
  }

  setBelongsTo(belongsTo) {
    this.belongsTo = belongsTo;
  }

  /* -------------------------
     -------------------------
     -------- GETTERS --------
     -------------------------
     ------------------------- */

  getSerialNumber() {
    return this.serialNumber;
  }

  getNodeAddress() {
    return this.nodeAddress;
  }

  getLastSeenTimestamp() {
    return this.lastSeenTimestamp;
  }

  getBatteryLevel() {
    return this.batteryLevel;
  }

  getLastHubRssi() {
    return this.lastHubRssi;
  }

  getFirmwareVersion() {
    return this.firmwareVersion;
  }

  getIsActive() {
    return this.isActive;
  }

  getLastHubUsedInfrared() {
    return this.lastHubUsedInfrared;
  }

  getAssignedToID() {
    return this.assignedToID;
  }

  getDisplayMessage() {
    return this.displayMessage;
  }

  getBadgeTypeID() {
    return this.badgeTypeID;
  }

  getLastPositionTimestamp() {
    return this.lastPositionTimestamp;
  }

  getLastSeenCoordinateID() {
    return this.lastSeenCoordinateID;
  }

  getLastSeenRoomID() {
    return this.lastSeenRoomID;
  }

  getLastSeenCoordinate() {
    return this.lastSeenCoordinate;
  }

  getLastSeenRoom() {
    return this.lastSeenRoom;
  }

  getBadgeType() {
    return this.badgeType;
  }

  getBelongsTo() {
    return this.belongsTo;
  }

  /* -------------------------
     -------------------------
     -------- HELPERS --------
     -------------------------
     ------------------------- */

  getIsConnected() {
    let diff = this.lastSeenTimestamp?.diff(DateTime.now(), ['minutes']).get('minutes');
    if (diff == null) return false;
    return Math.abs(diff) < 8640;
  }
}
