// Container for all elements to be rendered in the App
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Navigation
import AppBar from './AppBar';
import AlertList from './AlertList';

// Components
import SideMenu from './SideMenu';

// Pages
import DashboardMap from '../pages/dashboard/map';
import DashboardList from '../pages/dashboard/list';

import ChangePassword from '../pages/account/change-password';
import AccountSettings from '../pages/account/settings';

import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgot-password';
import TwoFactorSetup from '../pages/auth/two-factor/setup';
import TwoFactor from '../pages/auth/two-factor';

import AssignBadges from '../pages/admin/badges/assign';
import Badge from '../pages/admin/badges/[id]';
import Badges from '../pages/admin/badges';
import CreateBuildings from '../pages/admin/buildings/create';
import Building from '../pages/admin/buildings/[id]';
import Buildings from '../pages/admin/buildings';
import Floor from '../pages/admin/floors/[id]';
import CreateFloors from '../pages/admin/floors/create';
import Floors from '../pages/admin/floors';
import AssignHubs from '../pages/admin/hubs/assign';
import Hub from '../pages/admin/hubs/[id]';
import Hubs from '../pages/admin/hubs';
import AddRedAlertTextContact from '../pages/admin/red-alert-text-groups/[id]/add';
import RedAlertTextGroups from '../pages/admin/red-alert-text-groups';
import Room from '../pages/admin/rooms/[id]';
import CreateRooms from '../pages/admin/rooms/create';
import Rooms from '../pages/admin/rooms';
import StaffMember from '../pages/admin/staff/[id]';
import CreateStaff from '../pages/admin/staff/create';
import Staff from '../pages/admin/staff';
import CreateUsers from '../pages/admin/users/create';
import Users from '../pages/admin/users';
import Config from '../pages/admin';

import PageWrapper from './PageWrapper';

// Components
import StatusAlertModal from '../components/StatusAlertModal';
import { hideAlertModal } from '../redux/actions/statusActions';

const routeAndWrapperMapStateToProps = state => ({
  windowSize: state.ui.windowSize,
  userType: state.auth.userType,
  isMobile: state.ui.isMobile
});

class RouteAndWrapper extends Component {
  render() {
    return (
      <Route path={this.props.path}>
        <PageWrapper>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {this.props.sideMenu && !this.props.isMobile &&
            <SideMenu standalone height={this.props.windowSize.height} userType={this.props.userType} />}
            {this.props.children}
          </div>
        </PageWrapper>
      </Route>
    );
  }
}

RouteAndWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  sideMenu: PropTypes.bool,
  windowSize: PropTypes.object.isRequired,
  userType: PropTypes.object,
  isMobile: PropTypes.bool.isRequired
};

var ConnectedRouteAndWrapper = connect(routeAndWrapperMapStateToProps, null)(RouteAndWrapper);

// Props: windowSize
class Navigation extends Component {

  constructor(props) {
    super(props);

    this.dismissAlert = this.dismissAlert.bind(this);
  }

  dismissAlert() {
    this.props.hideAlertModal();
  }

  render() {
    return (
      <>
        <StatusAlertModal isVisible={this.props.showAlertModal} alertStatus={this.props.schoolStatus} dismissAlert={this.dismissAlert} />
        <AlertList />
        <AppBar />
        <Switch>
          {/* Config */}
          <ConnectedRouteAndWrapper path='/admin/badges/assign' sideMenu>
            <AssignBadges />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/badges/:id' sideMenu>
            <Badge />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/badges' sideMenu>
            <Badges />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/buildings/create' sideMenu>
            <CreateBuildings />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/buildings/:id' sideMenu>
            <Building />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/buildings' sideMenu>
            <Buildings />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/floors/create' sideMenu>
            <CreateFloors />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/floors/:id' sideMenu>
            <Floor />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/floors' sideMenu>
            <Floors />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/hubs/assign' sideMenu>
            <AssignHubs />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/hubs/:id' sideMenu>
            <Hub />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/hubs' sideMenu>
            <Hubs />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/red-alert-text-groups/:id/add' sideMenu>
            <AddRedAlertTextContact />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/red-alert-text-groups' sideMenu>
            <RedAlertTextGroups />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/rooms/create' sideMenu>
            <CreateRooms />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/rooms/:id' sideMenu>
            <Room />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/rooms' sideMenu>
            <Rooms />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/staff/create' sideMenu>
            <CreateStaff />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/staff/:id' sideMenu>
            <StaffMember />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/staff' sideMenu>
            <Staff />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/users/create/:user_id' sideMenu>
            <CreateUsers />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/users/create' sideMenu>
            <CreateUsers />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin/users' sideMenu>
            <Users />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/admin' sideMenu>
            <Config />
          </ConnectedRouteAndWrapper>

          {/* Account */}
          <ConnectedRouteAndWrapper path='/account/change-password'>
            <ChangePassword />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account/settings' sideMenu>
            <AccountSettings />
          </ConnectedRouteAndWrapper>

          {/* Auth */}
          <ConnectedRouteAndWrapper path='/login'>
            <Redirect to='/auth/login' />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/login'>
            <Login />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/forgot-password'>
            <ForgotPassword />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/two-factor/setup'>
            <TwoFactorSetup />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/two-factor'>
            <TwoFactor />
          </ConnectedRouteAndWrapper>

          {/* Dashboard */}
          <ConnectedRouteAndWrapper path='/dashboard/map'>
            <DashboardMap />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/dashboard/list'>
            <DashboardList />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/' exact>
            <Redirect to='/dashboard/map' />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/'>
            <Redirect to='/dashboard/map' />
          </ConnectedRouteAndWrapper>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = state => ({
  schoolStatus: state.status.schoolStatus,
  showAlertModal: state.status.showAlertModal
});

const mapDispatchToProps = dispatch => ({
  hideAlertModal: () => hideAlertModal(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

Navigation.propTypes = {
  hideAlertModal: PropTypes.func,
  showAlertModal: PropTypes.bool,
  schoolStatus: PropTypes.object
};
