import axios from 'axios';

// Redux
import { forceLogoutUser } from '../redux/actions/authActions';

// Library
import { AlertType, AlertUpdate, BadgeType, Building, Floor, Hub, Room, StaffMember, StaffType, Badge, RedAlertTextGroup } from '../lib';

// Constants
import { isNotNull, isString } from '../constants';
import User from '../lib/User';

// eslint-disable-next-line no-undef
const SERVER_URL = process.env.REACT_APP_SERVER_URL 
  ?? `${window.location.protocol}//api.${window.location.hostname.replace('www.', '')}/api`;

const axiosWithCredentials = axios.create({
  withCredentials: true,
  baseURL: SERVER_URL
});

axiosWithCredentials.interceptors.response.use(response => response,
  error => {
    if (error && error.response?.status === 401) forceLogoutUser();

    // Try to get the core error message
    let rejectError = error;
    if (isString(error)) rejectError = error;
    else if (isString(error?.response)) rejectError = error.response;
    else if (isString(error?.response?.data)) rejectError = error.response.data;
    else if (isString(error?.response?.data?.message)) rejectError = error.response.data.message;
    return Promise.reject(rejectError ? rejectError : error);
  });

export function loginUser(email, password) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/login', { email: email, password: password }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error);
    });
  });
}
export function forgotPassword(email) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/forgot-password', { email: email }).then(res => {
      resolve(res.data);
    }).catch(error => {
      reject(error);
    });
  });
}

export function verifyMultiFactor(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/multi-factor/verify', { token: token }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function confirmMultiFactor(token) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/multi-factor/confirm', { token: token }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function setupMultiFactor() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/user/multi-factor/setup').then(res => {
      resolve(res && res.data && res.data.image ? res.data.image : res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function logoutUser() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/logout').then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updatePassword(newPassword) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/update', { password: newPassword }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleUserActivity(userID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/toggle/' + userID).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createUser(user) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/', user).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getStaffMember(staffMemberID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/staff/' + staffMemberID).then(res => {
      resolve(StaffMember.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getRoom(roomID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/rooms/' + roomID).then(res => {
      resolve(Room.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteRoom(roomID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.delete('/rooms/' + roomID).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getHub(hubSerialNumber) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/hubs/' + hubSerialNumber).then(res => {
      resolve(Hub.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getBadge(badgeSerialNumber) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/badges/' + badgeSerialNumber).then(res => {
      resolve(Badge.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listBadgeTypes() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/badges/types').then(res => {
      resolve(BadgeType.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getBuilding(buildingID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/buildings/' + buildingID).then(res => {
      resolve(Building.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getFloor(floorID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/floors/' + floorID).then(res => {
      resolve(Floor.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listHubs() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/hubs').then(res => {
      resolve(Hub.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listBadges() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/badges').then(res => {
      resolve(Badge.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUnassignedHubs() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/hubs/unassigned').then(res => {
      resolve(Hub.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUnassignedBadges() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/badges/unassigned').then(res => {
      resolve(Badge.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listRooms() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/rooms').then(res => {
      resolve(Room.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listBuildings() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/buildings').then(res => {
      resolve(Building.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listFloors(buildingID = null) {
  return new Promise(function (resolve, reject) {
    const params = buildingID != null ? { buildingId: buildingID } : {};
    axiosWithCredentials.get('/floors', { params: params }).then(res => {
      resolve(Floor.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listStaff() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/staff').then(res => {
      resolve(StaffMember.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUnassignedStaff() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/staff/unassigned').then(res => {
      resolve(StaffMember.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUsers() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/user/list').then(res => {
      resolve(User.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getUser(userID = null) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/user/', { params: userID != null ? { userId: userID } : null }).then(res => {
      resolve(User.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSchoolStatus() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/school/status').then(res => {
      // Retrieve the alert status
      if (res.data?.alertType == null) reject('Error: Unable to retrieve school status.');
      const alertStatus = AlertType.thaw(res.data.alertType);

      // Retrieve alert update, if it exists
      const alertUpdate = AlertUpdate.thaw(res.data?.alertUpdate);

      resolve({ status: alertStatus, roomId: alertUpdate?.getRoomID() });
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 *
 * @param {number} [maximumCount] The maximum number of alerts to retrieve.
 * @returns {Promise<(AlertUpdate | null)[]>}
 */
export function getAlertUpdates(maximumCount = undefined) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/school/alert-updates', { params: { maximumCount } }).then(res => {
      resolve(AlertUpdate.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function setSchoolStatus(alertStatus) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/school/status', { alertStatus: alertStatus.getDashboardStatus() }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function setRoomStatus(roomID, alertStatus) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/rooms/status', { roomId: roomID, alertStatus: alertStatus.getDashboardStatus() }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function changeUserPassword(userID, password) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/password', {
      userId: userID,
      password: password
    }
    ).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateUser(user) {

  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/update', {
      userId: user.userID,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleMobileAccess(userID, hasAccess) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/mobile', {
      userId: userID,
      hasAccess: hasAccess
    }
    ).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getAdminSettings() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/settings').then(res => {
      if (!isNotNull(res.data)) reject('Something went wrong.');
      resolve(res.data);
    }).catch(error => {
      reject(error);
    });
  });
}

export function resetUserPassword(userID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/reset/' + userID).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function patchAdminSettings(settings) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch('/admin/settings', settings).then((res) => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateStaffMember(staffMemberObj) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();
    if (staffMemberObj.staffMemberImage) data.append('image', staffMemberObj.staffMemberImage);
    data.append('staffId', staffMemberObj.id);
    data.append('firstName', staffMemberObj.firstName);
    data.append('lastName', staffMemberObj.lastName);
    if (staffMemberObj.phoneNumber) data.append('phoneNumber', staffMemberObj.phoneNumber);
    if (staffMemberObj.email) data.append('email', staffMemberObj.email);
    data.append('staffTypeId', staffMemberObj.staffTypeID);
    if (staffMemberObj.badgeSerialNumber) data.append('badgeSerialNumber', staffMemberObj.badgeSerialNumber);
    if (staffMemberObj.assignedRoomID) data.append('assignedRoomId', staffMemberObj.assignedRoomID);

    axiosWithCredentials.patch(
      '/staff',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ).then((res) => {
      resolve(res);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetUser2fa(userID) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/user/multi-factor/reset/', {
      userId: userID
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listStaffTypes() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/staff/types/').then(res => {
      resolve(StaffType.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createBuilding({ name, code }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/buildings', {
      name,
      code
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateBuilding({ id, name }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/buildings/${id}`, {
      name
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteBuilding(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.delete(`/buildings/${id}`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSetupMode() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/setup').then((res) => {
      resolve(res.data);
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleSetupMode(setupMode) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/admin/setup', { setupMode: setupMode }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function setSetupMode(value) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/admin/setup', {
      setupMode: value
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createHub({ serialNumber, hubType, floorID, coordinate, roomID, notes }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/hubs', {
      serialNumber,
      hubType,
      roomId: roomID,
      notes,
      coordinate,
      floorId: floorID
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteHub(serialNumber) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.delete(`/hubs/${serialNumber}`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createBadge({ serialNumber, staffMemberID, badgeTypeID }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/badges', {
      serialNumber,
      staffMemberId: staffMemberID,
      badgeTypeId: badgeTypeID
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createRoom(floorID, identifier, vertices) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/rooms', {
      floorId: floorID,
      identifier: identifier,
      vertices
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateRoom(roomObj) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/rooms', {
      roomId: roomObj.id,
      floorId: roomObj.floorID,
      identifier: roomObj.identifier,
      vertices: roomObj.vertices
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createFloor({ buildingID, zIndex, identifier, floorPlanImage = null, latLeftTop, longLeftTop, imageWidthM }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();
    if (floorPlanImage != null) data.append('image', floorPlanImage);
    data.append('buildingId', buildingID);
    data.append('zIndex', zIndex);
    data.append('identifier', identifier);
    data.append('latLeftTop', latLeftTop);
    data.append('longLeftTop', longLeftTop);
    data.append('imageWidthM', imageWidthM);

    axiosWithCredentials.post(
      '/floors/',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ).then((res) => {
      resolve(res);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateFloor({ id, buildingID, zIndex, identifier, floorPlanImage = null, latLeftTop, longLeftTop, imageWidthM }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();
    if (floorPlanImage != null) data.append('image', floorPlanImage);
    data.append('buildingId', buildingID);
    data.append('zIndex', zIndex);
    data.append('identifier', identifier);
    data.append('latLeftTop', latLeftTop);
    data.append('longLeftTop', longLeftTop);
    data.append('imageWidthM', imageWidthM);

    axiosWithCredentials.patch(
      `/floors/${id}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ).then((res) => {
      resolve(res);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteFloor(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.delete(`/floors/${id}`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function createStaffMember(staffMemberObj) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();
    if (staffMemberObj.staffMemberImage) data.append('image', staffMemberObj.staffMemberImage);
    data.append('firstName', staffMemberObj.firstName);
    data.append('lastName', staffMemberObj.lastName);
    if (staffMemberObj.phoneNumber) data.append('phoneNumber', staffMemberObj.phoneNumber);
    if (staffMemberObj.email) data.append('email', staffMemberObj.email);
    data.append('staffTypeId', staffMemberObj.staffTypeID);
    if (staffMemberObj.badgeSerialNumber) data.append('badgeSerialNumber', staffMemberObj.badgeSerialNumber);
    if (staffMemberObj.assignedRoomID) data.append('assignedRoomId', staffMemberObj.assignedRoomID);

    axiosWithCredentials.post(
      '/staff/',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ).then((res) => {
      resolve(res);
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteBadge(serialNumber) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.delete(`/badges/${serialNumber}`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteStaffMember(staffMemberID) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/staff/toggle', {
      staffMemberId: staffMemberID,
      isActive: false
    }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listRedAlertTextGroups() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/red-alert-text-group').then(res => {
      resolve(RedAlertTextGroup.thawList(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteRedAlertTextContact(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.delete('/red-alert-text-group/contact/' + id).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getRedAlertTextGroup(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/red-alert-text-group/' + id).then(res => {
      resolve(RedAlertTextGroup.thaw(res.data));
    }).catch(error => {
      reject(error);
    });
  });
}

export function addRedAlertTextContact(id, { name, phoneNumber }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/red-alert-text-group/${id}/contact`, { name, phoneNumber }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateRedAlertTextGroup(id, { message }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post(`/red-alert-text-group/${id}`, { message }).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}