import React, { Component } from 'react';
import { Button, Container, CircularProgress, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { loginUser } from '../../../redux/actions/authActions';
import { setAlert } from '../../../redux/actions/alertActions';

// Constants
import { DEFAULT, GREY } from '../../../constants';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      notConfirmedError: false,
      alertText: ''
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    if (email === '') this.props.setError('Email cannot be empty.');
    else if (password === '') this.props.setError('Password cannot be empty');
    else {
      // Sets this.props.isLoggedIn to true if successful
      this.props.handleLogin(email, password);
    }
  };

  render() {
    return (
      <Container style={{ marginTop: '10%' }}>
        <h1
          style={{ color: GREY.dark, textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}
        >
          Login
        </h1>

        <form autoComplete='off' onSubmit={this.onSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* Email Field */}
              <TextField
                fullWidth
                type='email'
                id='email'
                label='Email'
                value={this.state.email}
                onChange={this.onChange}
                variant='outlined'
                required
              />
            </Grid>
            <Grid item xs={12}>
              {/* Phone Field */}
              <TextField
                fullWidth
                type='password'
                id='password'
                label='Password'
                value={this.state.password}
                onChange={this.onChange}
                variant='outlined'
                required
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                style={{ textTransform: 'none', backgroundColor: this.props.statusColor.main, color: this.props.statusColor.text }}
                disabled={this.props.isLoading}
                type='submit'>
                Submit
                {this.props.isLoading && <CircularProgress size={25} style={{ color: this.props.statusColor.text, marginLeft: '1em' }} />}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Container style={{ textAlign: 'center' }}>
          <p>
            If you have forgotten your password, <a href='/auth/forgot-password'>click here</a> to reset it.
          </p>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  statusColor: state.status.schoolStatus ? state.status.schoolStatus.getColor() : DEFAULT,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  setError: (msg) => setAlert(dispatch, msg),
  handleLogin: (email, password) => loginUser(dispatch, email, password)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
  setError: PropTypes.func,
  handleLogin: PropTypes.func,
  statusColor: PropTypes.object,
  isLoading: PropTypes.bool
};
