import { Redirect } from 'react-router-dom';
import { isNull, UserTypes } from '../constants';
import React from 'react';

export default function AuthRedirect(pathname, authProps) {
  const { isLoading, isAuthenticated, userID, passwordResetRequired, multiFactorSetupRequired, userType } = authProps;
  const isLoggedIn = !isNull(userID);
  pathname = pathname.toLowerCase();

  if (!isLoading) {

    // Login
    if (pathname.includes('/auth/login')) {
      if (isLoggedIn) {
        if (multiFactorSetupRequired) return <Redirect to="/auth/two-factor/setup" />;
        else if (!isAuthenticated) return <Redirect to="/auth/two-factor" />;
        else if (passwordResetRequired) return <Redirect to="/account/change-password" />;
        else return <Redirect to="/dashboard/map" />;
      }
    }

    // Forgot Password
    else if (pathname.includes('/auth/forgot-password')) {
      
      if (isLoggedIn) return <Redirect to="/dashboard/map" />;
      else return null;
    }

    // Two Factor Setup or Authentication
    else if (pathname.includes('/auth/two-factor')) {
      if (!isLoggedIn) return <Redirect to="/auth/login" />;
      else if (isAuthenticated) {
        if (passwordResetRequired) return <Redirect to="/account/change-password" />;
        else return <Redirect to="/dashboard/map" />;
      }
      else if (pathname.includes('/auth/two-factor/setup') && !multiFactorSetupRequired) <Redirect to="/auth/two-factor" />;
      else if (pathname.includes('/auth/two-factor') && multiFactorSetupRequired) <Redirect to="/auth/two-factor/setup" />;
    }

    // Change Password
    else if (pathname.includes('/account/change-password')) {
      if (!isLoggedIn || !isAuthenticated || multiFactorSetupRequired) return <Redirect to="/auth/login" />;
      else if (!passwordResetRequired) return <Redirect to="/dashboard/map" />;
    }

    // Any other non-auth page
    else {
      if (!isLoggedIn || !isAuthenticated || multiFactorSetupRequired || passwordResetRequired) return <Redirect to="/auth/login" />;

      // Super or IT-specific pages
      if (pathname.includes('/admin')) {
        if (userType.name !== UserTypes.IT.name && userType.name !== UserTypes.SUPER.name) return <Redirect to="/dashboard/map" />;
      }
    }
  }
  return null;
}
